import React, { useEffect } from "react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Page.scss";
import { useNavigate } from "react-router-dom";

const Page = ({
  header = true,
  footer = true,
  hasBackButton = false,
  children,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {header && <Header />}
      {hasBackButton && (
        <ArrowBackIcon
        className="BackButton"
          boxSize="40px"
          m="20px"
          color="white"
          cursor="pointer"
          background="orange.300"
          borderRadius="100px"
          onClick={goBack}
        />
      )}
      <main>
        <section className="Page">{children}</section>
      </main>
      {footer && <Footer />}
    </>
  );
};

export default Page;
