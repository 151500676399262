const VOICE_CARDS = [
  {
    id: 1,
    image: "boyko1.jpg",
    recording: "boiko.wav",
    actor: "Boyko",
  },
  {
    id: 2,
    image: "Kiro.jpg",
    recording: "Kiro-official.wav",
    actor: "Kiril",
  },
  {
    id: 3,
    image: "Slavi.jpg",
    recording: "slavi-official.wav",
    actor: "Slavi",
  },
  {
    id: 4,
    image: "IvoAndonov.jpg",
    recording: "IvoAndonov-official.wav",
    actor: "IvoAndonov",
  },
  {
    id: 5,
    image: "kopeikin.jpg",
        recording: "kopeikin-oficial.wav",
        actor: "Kostadin",
  },
  {
    id: 6,
    image: "StoyanKolev.jpg",
    recording: "StoyanKolevPozdrav.wav",
    actor: "StoyanKolev",
  },
  {
    id: 7,
    image: "Kobilkina.jpg",
    recording: "Kobilkina.wav",
    actor: "Kobilkina",
  },
  {
    id: 9,
    image: "Konstantin.png",
    recording: "koceto.wav",
    actor: "Konstantin",
  },
];

const CONFIG = {
  VOICE_CARDS,
};

export default CONFIG;
