import { Checkbox as ChCheckbox } from "@chakra-ui/react";

import "./Checkbox.scss";

const Checkbox = ({ id, name, label, value, onChange }) => {
  return (
    <ChCheckbox checked={value} onChange={onChange} colorScheme="orange">
      {label}
    </ChCheckbox>
  );
};

export default Checkbox;
