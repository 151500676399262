import { Box } from "@chakra-ui/react";
import Page from "../../components/Page/Page";

const Contacts = () => {
  return (
    <Page hasBackButton>
      <Box>Contacts</Box>
    </Page>
  );
};

export default Contacts;
