import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import TextArea from "../TextArea/TextArea";

const CreateGreetingForm = ({ actor }) => {
  const [email, setEmail] = useState("");
  const [greeting, setGreeting] = useState("");
  const [acceptedTc, setAcceptedTc] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleGreetingChange = (e) => setGreeting(e.target.value);
  const handleAcceptedTcChange = (e) => setAcceptedTc(e.target.checked);

  const validateForm = () => {
    const emailRegex = new RegExp(
      /^([\w\.\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/
    );
    const formErrors = {};

    if (email === "" || !emailRegex.test(email)) {
      formErrors.email = true;
    }

    if (greeting === "" || greeting.length >= 200) {
      formErrors.greeting = true;
    }

    if (!acceptedTc) {
      formErrors.acceptedTc = true;
    }

    setErrors(formErrors);
    return formErrors;
  };

  const onModalConfirm = async () => {
    setIsFetching(true);
    fetch("http://16.171.12.70:8080/greetings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        greetingText: greeting,
        email,
        actor,
      }),
    })
      .then(({ status }) => {
        setIsFetching(false);
        if (status === 200) {
          onClose();
          toast({
            title: "Вие успешно заявихте вашия поздрав",
            description: "Очаквайте мейл с вашия поздрав в най-скоро време.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          onClose();
          toast({
            title: "Възникна неочаквана грешка",
            description:
              "Нещо се обърка докато създавахме твоя поздрав. Моля, опитай отново по-късно.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const onSubmit = () => {
    const errorsAfterValidation = validateForm();

    if (!Object.keys(errorsAfterValidation).length) {
      onOpen();
    }
  };

  return (
    <Box>
      <FormControl isInvalid={errors?.email} isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          value={email}
          onChange={handleEmailChange}
          focusBorderColor={errors?.email ? "red.400" : "orange.100"}
        />
        {!errors?.email ? (
          <FormHelperText>
            На този email адрес ще получите вашия поздрав
          </FormHelperText>
        ) : (
          <FormErrorMessage>Моля въведете валиден email адрес</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={errors?.greeting} isRequired mt={6}>
        <FormLabel>Текст за поздрав</FormLabel>
        <TextArea
          value={greeting}
          onChange={handleGreetingChange}
          focusBorderColor={errors?.greeting ? "red.400" : "orange.100"}
        />
        {errors?.greeting && (
          <FormErrorMessage>
            Поздравът трябва да е между 1 и 200 символа
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={errors?.acceptedTc} isRequired mt={6}>
        <Checkbox
          label={
            <Text ml={2}>
              Съгласен съм с{" "}
              <Link color="orange.500" href="/terms-and-conditions">
                общите условия за ползване
              </Link>
            </Text>
          }
          value={acceptedTc}
          onChange={handleAcceptedTcChange}
        />
      </FormControl>
      <Button mt={6} onClick={onSubmit} isLoading={isFetching}>
        Създай поздрав
      </Button>
      <Text display="block" as="i" fontSize="10px" mt={2}>
        * Доставката се случва обикновено в рамките на 1-2 часа, но в някои
        случаи може да отнеме до 48 часа.
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt={20} ml={3} mr={3}>
          <ModalHeader>Потвърджение</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="16px">
              След изпращането на вашата заявка ние ще проверим дали има
              извършено плащане към нашата сметка и само тогава ще създадем
              вашия персонализиран поздрав. Моля, потвърдете, че сте извършили
              плащане.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isFetching}
              color="white"
              background="purple.400"
              mr={3}
              onClick={onClose}
              _hover={{
                background: "purple.500",
                color: "gray.100",
              }}
            >
              Назад
            </Button>
            <Button
              isLoading={isFetching}
              color="white"
              background="orange.400"
              onClick={onModalConfirm}
              _hover={{
                background: "orange.500",
                color: "gray.100",
              }}
            >
              Продължи
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateGreetingForm;
