import { Box, Flex, Text } from "@chakra-ui/react";
import Page from "../../components/Page/Page";
import VoiceCard from "../../components/VoiceCard/VoiceCard";
import CONFIG from "../../utils/config";
import "./Home.scss";

const Home = () => {
  return (
    <Page>
      <div className="Home">
        <div className="VoiceCards">
          {CONFIG.VOICE_CARDS.map(({ id, image, recording, actor }) => (
            <VoiceCard key={id} image={image} recording={recording} actor={actor} />
          ))}
        </div>
      </div>
      <Flex justifyContent="center" alignItems="center">
        <Text as="i" textAlign="center">Имаш различна идея или запитване за бизнес услуги? 
          Сподели ни я на <b>pozdravimebginfo@gmail.com</b>
        </Text>
      </Flex>
    </Page>
  );
};

export default Home;
