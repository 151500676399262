import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FaFacebook, FaTiktok, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const goToContacts = () => navigate('/contacts')
  const goToTcs = () => navigate('/terms-and-conditions')

  return (
    <Flex
      className="Footer"
      h="100px"
      w="100%"
      bg="#FFFFFF"
      boxShadow="dark-lg"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        className="SocialMedias"
        h="100%"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        gap="5px"
      >
        <Flex gap="5px">
        <a href="https://www.facebook.com/profile.php?id=100094518365111">
  <Button borderRadius="full" p={2} bg="orange.400" _hover={{ background: "orange.500" }}>
    <FaFacebook size="20px" />
  </Button>
</a>
          <a href="https://www.tiktok.com/@pozdravime.bg?lang=en&fbclid=IwAR3151QsG-3Ak1-cquP1rpojs6xdPil5xSS6BsCHfPTYBKjuiqTgIDCUfr4">
  <Button borderRadius="full" p={2} bg="orange.400" _hover={{ background: "orange.500" }}>
    <FaTiktok size="20px" />
  </Button>
</a>
          <a href="https://www.instagram.com/pozdravimebg/">
  <Button borderRadius="full" p={2} bg="orange.400" _hover={{ background: "orange.500" }}>
    <FaInstagram size="20px" />
  </Button>
</a>
        </Flex>
        <Box>
          <Button
            h="fit-content"
            bg="none"
            color="orange.400"
            _hover={{
              background: "none",
              color: "orange.500",
            }}
            onClick={goToTcs}
          >
            <Text fontWeight="bold">Общи условия</Text>
          </Button>
          <Button
            h="fit-content"
            bg="none"
            color="orange.400"
            _hover={{
              background: "none",
              color: "orange.500",
            }}
            onClick={goToContacts}
          >
            <Text fontWeight="bold">Контакти</Text>
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Footer;
