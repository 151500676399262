import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Image from "../Image/Image";

const Header = () => {
    const navigate = useNavigate();
  const logoSrc = `${window.location.origin}/logos/pozdravime.png`;

  const navigateToHome = () => navigate('/')
  
  return (
    <Box className="Header" h="70px" w="100%" bg="#FFFFFF" boxShadow="md">
      <Image
        src={logoSrc}
        alt="pozdravime_logo"
        maxW="100%"
        maxH="100%"
        p={3}
        cursor="pointer"
        onClick={navigateToHome}
      />
    </Box>
  );
};

export default Header;
