import { Flex, Text } from "@chakra-ui/react";
import Page from "../../components/Page/Page";

const TermsAndConditions = () => {
  const textSharedStyles = { mb: "20px" };
  return (
    <Page hasBackButton>
      <Flex justifyContent="center" flexDir="column" alignItems="center" lineHeight="35px">
        <Text fontSize="30px" fontWeight={800} {...textSharedStyles}>
          ОБЩИ УСЛОВИЯ ЗА ПОЛЗВАНЕ НА ПРЕДОСТАВЯНИТЕ УСЛУГИ
        </Text>
        <Text {...textSharedStyles} as="i">
          Настоящите Общи условия съдържат описанието на предоставяните от
          „ПОЗДРАВИМЕ“ ООД, посредством сайта „pozdravime.bg”, услуги и уреждат
          отношенията между „ПОЗДРАВИМЕ” ООД и потребителите на Услуги през
          сайта „pozdravime.bg”.
        </Text>
        <Text {...textSharedStyles}>
          <b>I. Дефиниции.</b> <br />• „ПОЗДРАВИМЕ“ ООД, наричано по-долу
          ТЪРГОВЕЦА, е търговско дружество в процес на учредяване и регистрация
          в Търговски регистър, със седалище и адрес на управление гр. Плевен,
          ул. „Шейново“ 20, ап. 9. До момента на неговата регистрация, от името
          на дружеството ще договаря съдружникът Илко Светозаров Симеонов, адрес
          гр. Плевен, ул. „Шейново“ 20, ап. 9, email
          ilko.simeonov2000@gmail.com, тел. 0885181556, като правата и
          задълженията ще възникват за съдружниците, след което ще преминат по
          право върху възникналото дружество.
          <br />• Потребител е всяко лице, което ползва Услугите, които са
          достъпни посредством сайта pozdravime.bg;
          <br />• Сайт/уебсайт е съвкупност от взаимно свързани страници, които
          са достъпни чрез своя постоянен адрес (URL) и съдържат електронни
          документи, текст, картина, изображения или други материални,
          форматирани посредством HTML или друг протокол и достъпвани
          посредством HTTP или друг протокол.
          <br /> • Услуги, предоставяни посредством сайта „pozdravime.bg ” на
          потребителите, са услуги като: софтуерна изработка по поръчка на
          гласов звуков запис, въз основа на текст, предоставен от Потребителя
          писмено или гласно, за лични (нетърговски) цели. Услугите предоставяне
          на сайта са предмет на постоянно развитие и допълване и се предоставят
          само при условията на тези Общи условия и описанието на сайта
          „pozdravime.bg”.
        </Text>
        <Text {...textSharedStyles}>
          <b>II. Защита на личните данни.</b>
          <br />• ТЪРГОВЕЦЪТ събира и използва лични данни относно своите
          Потребители, за да им предостави Услуги посредством сайта
          „pozdravime.bg”. При ползване на Услугите на сайта „pozdravime.bg”
          Потребителят предоставя своя имейл адрес, както и всяка друга
          информация, която Потребителят въвежда, използва или доброволно
          предоставя при достъп или ползване на услуги посредством сайта
          „pozdravime.bg” или по друг път като телефон, имейл и т.н. При
          спазване на действащото законодателство и клаузите на настоящите Общи
          условия, ТЪРГОВЕЦЪТ може да използва личните данни на Клиента
          единствено и само за целите, предвидени в настоящите Общи условия.
          Всякакви други цели, за които се използват данните, ще бъдат
          съобразени с българското законодателство, приложимите международни
          актове, Интернет етиката, правилата на морала и добрите нрави.
          <br />• ТЪРГОВЕЦЪТ гарантира на своите Потребители конфиденциалността
          на предоставената информация и лични данни. Последните няма да бъдат
          използвани, предоставяни или довеждани до знанието на трети лица извън
          случаите и при условията, посочени в настоящите Общите условия. В
          случай, че Потребителят откаже да предостави своите лични данни на
          ТЪРГОВЕЦА в пълнота, то ТЪРГОВЕЦЪТ ще бъде в невъзможност да изпълни
          заявката за Услуги постъпила посредством сайта „pozdravime.bg” или по
          друг начин. В СЛУЧАЙ, ЧЕ ПОТРЕБИТЕЛЯТ, ПРИ ПОЛЗВАНЕТО НА УСЛУГИ
          ПОСРЕДСТВОМ САЙТА „pozdravime.bg”, ПРЕДОСТАВИ ЛИЧНИ ДАННИ ОТНАСЯЩИ СЕ
          ДО ТРЕТО ЛИЦЕ КАТО ИМЕНА И/ИЛИ ДРУГИ ДЕТАЙЛИ, ПОТРЕБИТЕЛЯТ ДЕКЛАРИРА,
          ЧЕ Е ПОЛУЧИЛ ПРЕДВАРИТЕЛНО, ИЗРИЧНО СЪГЛАСИЕ ОТ ЛИЦЕТО, ЗА КОЕТО СЕ
          ОТНАСЯТ ДАННИТЕ, ИЛИ НЕГОВ ЗАКОНЕН ПРЕДСТАВИТЕЛ, ПРЕДИ ДА ГИ
          ПРЕДОСТАВИ НА ТЪРГОВЕЦА.
          <br />• ТЪРГОВЕЦЪТ се задължава да не разкрива никакви лични данни за
          Клиента на трети лица – държавни органи, търговски дружества,
          физически лица и други, освен в случаите когато е получил изричното
          писмено съгласие на Клиента, информацията е изискана от държавни
          органи или длъжностни лица, които според действащото законодателство
          са оправомощени да изискват и събират такава информация.
        </Text>
        <Text {...textSharedStyles}>
          <b>
            III. Сключване, изпълнение и срок на договора за предоставяне на
            Услугите.
          </b>
          <br />• За да заяви Услуга, Потребителят попълва необходимата
          информация, след което на Потребителя се предоставя възможност да се
          съгласи с настоящите Общи условия. След натискане на бутона “Направи
          поръчка”, потребителят се съгласява да заплати Услугата. Потребителят
          получава потвърждение на поръчката и с получаване на това потвърждение
          се счита, че договорът е сключен. <br />• Потребителят декларира, че
          при заявка за Услуга, която включва използването на търговската марка
          или интелектуалната собственост, потребителят е законово упълномощен
          да предостави правото за използване на интелектуалната собственост на
          ТЪРГОВЕЦА за извършване на Услугата. <br />• Указаната цена за
          отделните услуги е крайна и за 1 (един) брой/звуков запис. Доставката
          на звуковия запис ще се извършва електронно, чрез предоставения имейл
          адрес на Потребителя. <br />• Срокът за изработка и доставка на
          звуковия запис е ориентировъчен, като може да варира в рамките на 1-3
          дни, в зависимост от заявената Услуга. <br />• Договорът се счита за
          изпълнен с осъществяване на доставката на заявената услуга и
          получаването й от Потребителя.
        </Text>
        <Text {...textSharedStyles}>
          <b>IV. Права и задължения на страните.</b> <br />• Потребителят е
          длъжен да заплати цената на поръчката си според обявеният начин на
          уебсайта „pozdravime.bg“. <br />• Потребителят е длъжен да не нарушава
          чужди имуществени или неимуществени, абсолютни или относителни права и
          интереси, като право на собственост, право на интелектуална
          собственост и др. <br />• Потребителят се задължава да обезщети
          ТЪРГОВЕЦА и всички трети лица за всички претърпени вреди и пропуснати
          ползи, включително за всякакви разходи и платени адвокатски
          възнаграждения, настъпили вследствие на предявени искове от и/или
          платени обезщетения на трети лица във връзка с Услуги, които
          Потребителят е използвал посредством „pozdravime.bg“ в нарушение на
          закона, настоящите Общи условия, добрите нрави или Интернет етиката.{" "}
          <br />• ТЪРГОВЕЦЪТ няма задължението и обективната възможност да
          контролира начина, по който потребителите използват предоставяните
          услуги. <br />• ТЪРГОВЕЦЪТ има право, без уведомяване на Потребителя,
          когато последният ползва услугите в нарушение на настоящите условия,
          както и по преценка на ТЪРГОВЕЦА, да откаже предоставянето на Услуга,
          като дължи връщане на пълната цена, ако такава е платена от
          Потребителя. ТЪРГОВЕЦЪТ не носи отговорност спрямо потребителите и
          трети лица за претърпени вреди и пропуснати ползи, настъпили
          вследствие отказа или ограничението на предоставянето на Услугите.{" "}
          <br />• ТЪРГОВЕЦЪТ след получаване на плащането се задължава да
          изработи и достави на Потребителя заявената от него Услуга. <br />•
          ТЪРГОВЕЦЪТ не носи отговорност за възможни инцидентни пропуски,
          свързани с актуалността на информацията, която поддържа в уебсайта; не
          носи отговорност за последиците, в т.ч. евентуални вреди, породени от
          или свързани по какъвто и да е начин с достъпа или използването на
          този уебсайт; не носи отговорност за ненакърнимостта на информацията в
          уебсайта от компютърни вируси или други заплахи; не носи отговорност
          за съдържанието на текстовете, които Потребителите предоставят във
          връзка с предоставянето на Услугите, както и за тяхното последващо
          използване. ТЪРГОВЕЦЪТ не гарантира безпроблемен и непрекъснат достъп
          до сайта „pozdravime.bg”. <br />• ТЪРГОВЕЦЪТ не носи отговорност за
          неизпълнение на задълженията си по този договор, поради настъпване на
          обстоятелства, които ТЪРГОВЕЦЪТ не е предвидил и не е бил длъжен да
          предвиди – вкл. случаи на случайни събития, проблеми в глобалната
          мрежа на Интернет и в предоставянето на услугите извън контрола на
          ТЪРГОВЕЦА.
        </Text>
        <Text {...textSharedStyles}>
          <b>V. Връщане</b> <br />• Поради специфичния и персонализиран характер
          на предлагания от нас продукт, ТЪРГОВЕЦЪТ си запазва правото да не
          връща заплатените суми. Упражнява това право на основание чл. 57 от
          Закона за защита на потребителите. VI. Промяна на Общите условия.{" "}
          <br />• Настоящите общи условия могат да се променят по всяко време от
          ТЪРГОВЕЦА, като всички изменения и допълнения влизат в сила с
          публикуването им на сайта „pozdravime.bg” или след информиране на
          Потребителите по влезли в сила договори. Свържи се с нас на
          pozdravimebg@gmail.com, за да съобщиш за нарушение на настоящите Общи
          условия или да отправиш каквито и да е въпроси във връзка с Общите
          условия или Услугите.
        </Text>
      </Flex>
    </Page>
  );
};

export default TermsAndConditions;
