import Button from "../Button/Button";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import { useNavigate } from "react-router-dom";
import VoiceCardImage from "../VoiceCardImage/VoiceCardImage";
import { Flex, Text } from "@chakra-ui/react";

const VoiceCard = ({ image, recording, actor }) => {
  const navigate = useNavigate();

  const onVoiceCardSelect = () => {
    navigate(`/${actor}`);
  };

  return (
    <Flex
      className="VoiceCard"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      gap="20px"
      p="10px"
    >
      <VoiceCardImage image={image} onVoiceCardSelect={onVoiceCardSelect} />
      <Text className="VoiceCardCelebrity" color="gray" fontSize="26px">
        {actor}
      </Text>
      <Flex className="VoiceCardButtons" flexDir="column" gap="10px">
        <AudioPlayer recording={recording} />
        <Button
          label="Направи поздрав"
          onClick={onVoiceCardSelect}
          color="white"
          background="orange.400"
          _hover={{
            background: "orange.500",
            color: "gray.100",
          }}
        />
      </Flex>
    </Flex>
  );
};

export default VoiceCard;
