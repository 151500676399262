import { Image, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import CreateGreetingForm from "../../components/CreateGreetingForm/CreateGreetingForm";
import Page from "../../components/Page/Page";
import VoiceCardImage from "../../components/VoiceCardImage/VoiceCardImage";
import CONFIG from "../../utils/config";
import "./CreateGreeting.scss";

const CreateGreeting = () => {
  const { actor } = useParams();
  const bankTransferImage = `${window.location.origin}/images/bank.jpg`;

  const { image, recording } = CONFIG.VOICE_CARDS.find(
    (voiceCard) => voiceCard.actor === actor
  );

  return (
    <Page hasBackButton>
      <div className="CreateGreeting">
        <div className="CreateGreetingSummary">
          <Text className="CreateGreetingText"  color="gray" fontSize="26px" >Направи поздрав от {actor}</Text>
          <VoiceCardImage image={image} />
          <AudioPlayer recording={recording} />
        </div>
        <div className="GreetingInfo">
          <div className="GeneralHowTo">
            <Text className="RevolutInfo" color="gray">
              <b>1. Всеки поздрав е на цена 20 лв. <br />
              
                 Като бележка към превода въведи мейл адреса, на който искаш да получиш поздрава (виж изображението).</b> 
              <Image src={bankTransferImage} alt="bank_transfer_pozdravime" mt="20px"/>
              <br /> Заплатете на линка отдолу
              <Text className="RevolutInfo" color="blue.500">
                 <a color="light blue.500" href="https://revolut.me/pozdravimebg">Линк към плащане</a>
               </Text>
              
            </Text>
            <Text className="HowToInfo" color="gray">
              <b>2. Въведи email адреса от превода и текста за поздрав, който искаш в полетата долу.</b>
            </Text>
            <Text className="ProcedureInfo" color="gray">
              <b>3. След като потвърдим, че плащането е извършено, ние ще създадем
              твоя персонализиран поздрав и ще ти го изпратим.</b>
            </Text>
          </div>
          <CreateGreetingForm actor={actor} />
        </div>
      </div>
    </Page>
  );
};

export default CreateGreeting;
